// @flow
import React from "react";

const Symbol = (props: {
  fileName: string,
  className?: string,
  style?: any,
}): React.Element<*> => {
  let { fileName, className, style } = props;
  let classNames = "symbol" + (className ? " " + className : "");

  return (
    <svg
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={classNames}
      style={style}
    >
      <use xlinkHref={"#symbols-" + fileName} />
    </svg>
  );
};

export default Symbol;
