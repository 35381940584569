// @flow
import React, { PureComponent } from "react";
import Layout from "../components/layouts";
import Symbols from "../components/shared/Symbol";

type PropType = {};
type StateType = {};

class HomePage extends PureComponent<PropType, StateType> {
  state = {};
  render() {
    return (
      <Layout>
        <div className="home">
          <div>
            <Symbols fileName="logo" />
            <h1 className="coming-soon">Full Site Coming Soon</h1>
          </div>
        </div>
      </Layout>
    );
  }
}

export default HomePage;
